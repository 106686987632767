<template>
  <!--新增对话框-->
  <el-dialog title="发货" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false"
    :before-close="handleClose">
    <el-form :model="editForm" :rules="editFormRules" ref="editForm">
      <el-form-item label="物流单号" prop="shipmentsLogisticCode" label-width="100px">
        <el-input v-model="editForm.shipmentsLogisticCode" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm('editForm')">取 消</el-button>
      <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
import TinymceEditor from "../inc/TinymceEditor";
export default {
  name: "AddUpdateVehicletype",
  components: {
    VploadImgTwo, TinymceEditor
  },
  data() {
    return {
      editForm: {},
      dialogVisible: false,
      editFormRules: {
        shipmentsLogisticCode: [
          { required: true, message: '请输入物流单号', trigger: 'blur' }
        ],
        shipmentsLogisticNmae: [
          { required: true, message: '请输入物流公司', trigger: 'change' }
        ]
      },
    }
  },

  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.$axios.post('/admin/exchangebill/deliverGoods', this.editForm)
            .then(res => {
              this.$message({
                showClose: true,
                message: '恭喜你，操作成功',
                type: 'success',
                onClose: () => {
                  this.$emit('refreshDataList')
                }
              });
              this.dialogVisible = false
              this.resetForm(formName)
            })
        } else {
          return false;
        }
      });
    },
    init(id) {
      this.dialogVisible = true;
      this.editForm.exchangeBillId = id
    },
  }
}
</script>

<style scoped>
.dialog-footer {
  text-align: center !important;
}
</style>
